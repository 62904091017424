.grid_content {
    padding-left: 0px !important;
    padding-right: 0px !important;
}
.navbar_bg_container {
    background-color: #b11e1d !important;
    padding: 0px 10px 0px 0px !important;
    height: 85px;
}
.navbar_bg_container .navbar_logo_content .bg_image {
    margin-left: 1rem;
}
.navbar_bg_container .navbar-collapse {
    background-color: #b11e1d !important;
    margin-right: -10px;
}
.menubar_nav_content {
    position: absolute;
    right: 30px;
}
.navbar_container {
    justify-content: flex-end !important;
    align-items: flex-end !important;
    width: 100%;
}
.navbar-light .navbar-toggler-icon {
    background-image: url("../../assets/humburger.png") !important;
  }
.navbar-toggler {
    border: none !important;
}
.navbar-toggler:focus {
    text-decoration: none !important;
    box-shadow: none !important;
}
.menubar_nav_content a {
    text-decoration: none !important;
}
.menubar_nav_content .nav_text {
    color: #fff !important;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none !important;
    padding: 5px 10px !important;
}
.menubar_nav_content .nav_text:hover {
    font-size: 18px;
}


/* TABLET MEDIA QUERY */

@media (max-width: 990px) {
    .navbar_bg_container .navbar_logo_content {
        margin-left: 5px !important;
    }
    .navbar_container {
        justify-content: flex-start !important;
        align-items: flex-start !important;
        margin-top: 20px !important;
        width: unset !important;
    }
    .menubar_nav_content {
        position: relative !important;
        right: unset !important;
        margin-top: 10px;
        margin-left: 24px;
    }
}
@media (max-width : 776px) {
    .navbar_bg_container .navbar_logo_content .bg_image {
        margin-left: 0px;
    }
} 
