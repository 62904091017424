.footer_container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 5px 15px;
  padding: 10px 10px;
}
/* .footer_grid_container {
  display: flex;
  flex-wrap: wrap;
} */
.footer_nav_grid_content {
  width: fit-content !important;
  padding: 0px !important;
  text-align: center;
}
.footer_container .footer_contact_content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  /* text-align: center; */
  margin: 10px 0px;
}
.footer_container .footer_contact_content span a {
  color: #fff;
  font-size: 14px;
  text-align: left;
  text-decoration: none;
  font-weight: 400;
  margin: 5px 12px 0px 0px;
}
.footer_container .footer_contact_content span a:hover {
  font-size: 15px;
}
.footer_social_icon_container {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0px !important;
  margin: 0px;
}
.footer_social_icon_container li {
  font-size: 34px;
}

.footer_social_icon_container li svg {
  width: 18px !important;
  height: 18px;
  background-color: #fff;
  text-align: center;
  line-height: 80px;
  font-size: 27px;
  margin: 10px 5px;
  display: block;
  padding: 6px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  border: 3px solid #fff;
}

.footer_social_icon_container li svg {
  position: relative;
  color: #262626;
  transition: 0.5s;
  z-index: 3;
}

.footer_social_icon_container li svg:hover {
  transform: rotateY(360deg);
  cursor: pointer;
}

.footer_social_icon_container li svg:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f00;
  transition: 0.5s;
  z-index: 2;
}

/* .footer_social_icon_container li svg:hover:before {
  top: 0;
} */

.footer_social_icon_container li:nth-child(1) svg:hover {
  color: #fff;
  background: #3b5999;
}

.footer_social_icon_container li:nth-child(2) svg:hover {
  background:#FF0000;
  color: #fff;

}

.footer_social_icon_container li:nth-child(3) svg:hover {
  background: #0077b5;
  color: #fff;

}

.footer_social_icon_container li:nth-child(4) svg:hover {
  background: #dd4b39;
  color: #fff;

}

@media (max-width : 992px) {
  .footer_grid_container {
    margin-top: 20px;
  }
  .footer_social_icon_container {
    margin-top: 5px !important;
  }
  .footer_social_icon_container {
    padding-left: 6px;
  }
}
@media (max-width: 767px) {
  .footer_container{
    margin: 0px !important;
  }
  .footer_container .footer_contact_content {
    margin-left: 0px !important;
  }
  /* .footer_container .footer_contact_content span a {
    margin-right: 22px;
  } */
}
