/* GENERAL CSS */
.section_grid_container {
    margin: 100px 0px 30px;
    /* height: 75vh; */
}
/* ABOUT US SECTION CSS */
.main_head_content_container {
    margin: 45px 5px;
    text-align: center;
}
.main_head_content{
    font-size: 32px !important;
    color: #b11e1d;
    font-weight: 700;
    text-transform:uppercase;
} 
.sub_heading_content_container {
    margin: 40px 0px 10px;
}
.sub_heading_content {
    color: #b11e1d;
    font-size: 24px;
}
.desc_content {
    color: #6c6464;
    margin-left: 10px;
}
.span_content {
    font-weight: 700;
    color: #333;
    text-transform: uppercase;
    color: #863bd9;
}
.our_goal_content_container {
    background-color: lightgray;
    padding: 20px 10px;
    text-align: center;
}
.our_goal_content_container h5 {
    font-size: 28px;
    font-weight: 600;
    text-align: left;
    margin: 10px 5px;
}
.service_section {
    margin: 40px 5px;
}
/* FAQs CSS */
.faq_ques_content {
    font-size: 15px;
    color: #0e687c;
    margin-left: 10px;
}


@media(min-width: 992px) {
    .h_align {
        height: 75vh !important;
    }
}