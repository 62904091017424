/* BOOKING INFO CARD CSS*/
.booking_info_card_container {
  align-items: center;
  margin: 12px 10px;
  border: none !important;
}
.booking_info_card_container .card-body {
    margin-top: 10px;
    display: flex;
}
.booking_info_card_container .card-body .span {
  font-size: 75px;
  color: #4f5ace;
}
.booking_info_card_container .card-body .card-title {
  display: flex;
  align-items: center;
  font-size: 28px;
  margin: 30px 15px;
  width: 100%;
}
.img_container {
  width: 200px !important;
  margin-right: 10px;
}
/* PARTNER CARD CSS */
.partner_card_container {
  align-items: center !important;
  justify-content: center;
  /* box-shadow: 0px 2px 4px -2px #4f5ace; */
  /* box-shadow: 0px 0px 4px 2px; */
  /* margin: 0px 6px 50px; */
  margin: 6px 14px 40px;
  /* padding: 0px 30px; */
  padding: 0px 25px;
  /* height: 250px; */
  height: 200px;
  border: none !important;
  text-align: center;
  background-color: rgb(243, 244, 249) !important;
  border-radius: 20px !important;
}
.partner_card_container:hover {
  cursor: pointer;
}
.partner_card_img_content {
  width: 200px !important;
  padding: 12px 8px;
}
.partner_card_container .card-title {
  font-size: 26px;
  color: #4f5ace;
  margin: 0px 5px 10px;
  width: 100%;
}
/* FEEDBACK CARD CSS */
.feedback_card_container {
  align-items: center;
  padding: 10px 5px;
  margin: 15px;
  border: none !important;
  text-align: center;
}
.feedback_card_img_content {
  width: 40% !important;
  border-radius: 100% !important;
}

/* DOWNLOAD APP */
.download_app_card_container {
  flex-direction: row !important;
  border: none !important;
  margin: 10px 0px 10px 5px;
}
.download_app_card_container:hover {
  cursor: pointer;
}
.download_image_grid {
  padding: 0px;
  border-radius: 5px;
}
.apple_img {
  width: 200px;
}

/* FOR LARGE SCREEN */
@media(min-width: 992px) {
  /* .partner_card_container {
    height: 34vh;
  } */
}
/* FOR SMALL SCREEN */
@media(max-width: 574px) {
  /* .partner_card_container {
    width: 307px;
    height: unset !important;
  } */
  .partner_card_container .card-title {
    padding-bottom: 6px !important;
  }
}