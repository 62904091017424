.footer_nav_grid_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 50px 0px 25px;
    background-color: #cbe5ed;
}
.footer_nav_grid_container p {
    font-size: 18px;
    font-weight: 600;
    text-align: left;
    margin: 5px 10px;
}
.footer_copy_right_content {
    display: flex;
    justify-content: center;
    text-align: center;
    color: #fff;
    margin: 8px;
    font-size: 10px;
    font-weight: 500;
}

@media(max-width: 1200px) {
    .footer_nav_grid_container {
        justify-content: flex-start !important;
    }
}