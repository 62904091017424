.carousel_container {
  top: 80px;
}
.carousel_container .carousel-indicators button {
  opacity: 1 !important;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  margin: 0px 10px;
}
.grid_slider_content {
  padding-right:  0px !important;
  padding-left: 0px !important;
}
.grid_slider_content .carousel-item img {
  height: 85vh !important;
}


@media (max-width: 992px) {
  .grid_slider_content .carousel-item img {
    height: unset !important;
  }
}
