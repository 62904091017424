/* GENRAL CSS */
.grid_section_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.p_60 {
  padding: 50px 10px !important;
}
.p_50 {
  padding: 50px 10px !important;
}
/* BOOKING INFO SECTION */
.home_page_booking_info_section_container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 60px;
}
.home_page_section_content h2 {
  font-size: 42px;
  text-align: center;
  font-weight: 500;
  font-family: Poppins, sans-serif;
  margin-bottom: 70px;
}

/* DOWNLOAD APP SECTION */
.dowload_app_content_img .download_app_btn_container {
  width: 50%;
  display: flex;
  justify-content: center;
}
.dowload_app_content_img h3 {
  color: #fff;
  background-color: #b11e1d;
  width: fit-content;
  padding: 8px;
  border-radius: 12px;
  margin-top: 50px;
}
.dowload_app_content_img .downlad_card_col {
  display: flex;
  flex-wrap: wrap;
}
.home_page_download_app_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 30px;
}
/* .home_page_download_app_container img {
  max-width: 100%;
} */
.dowload_app_content h5 {
  font-size: 32px;
  text-shadow: 0px 2px 4px #333 !important;
  margin: 5px 0px 15px;
  font-weight: 700;
}
.dowload_app_content p {
  width: 76%;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  margin-top: 5px;
  text-align: justify;
}
.main_app_bg_container {
  margin: 15px 0px 0px;
}

/* FOOTER CSS */
.footer_container {
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 767px) {
  .dowload_app_content p {
    width: 100%;
  }
  .dowload_app_content_img {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
  }
  .p_50 {
    padding: 15px 0px !important;
  }
  .home_page_section_content h2 {
    font-size: 34px;
    margin-bottom: 55px;
  }
  .dowload_app_content_img .downlad_card_col {
    justify-content: center;
    margin: 12px 0px;
  }
  .dowload_app_content h5 {
    text-align: center;
  }
}
